import * as Sentry from '@sentry/react';
import axios from 'axios';
import Cookies from 'js-cookie';
import isNull from 'lodash/isNull';
import split from 'lodash/split';
import Router from 'next/router';

import { getDecodedSessionToken } from './token';

const axiosConfig = axios.create({});

axiosConfig.interceptors.response.use(
  (response) => response,
  (axiosError) => {
    const error = new Error(axiosError.response?.data?.message, {
      cause: axiosError,
    });

    error.name = `${axiosError.response?.data?.statusCode} > ${axiosError.response?.data?.message}`;

    throw error;
  },
);

export const setAuthorizationHeader = (): void => {
  const accessToken = Cookies.get('access_token');

  if (accessToken) {
    axiosConfig.defaults.headers.common = {
      Authorization: `Bearer ${accessToken}`,
    };
  }
};

export const setToken = (accessToken?: string): void => {
  if (!accessToken) {
    return;
  }

  Cookies.set('access_token', accessToken, { expires: 365 });

  axiosConfig.defaults.headers.common = {
    Authorization: `Bearer ${accessToken}`,
  };
};

setAuthorizationHeader();

const functionMethodMap = {
  get: axiosConfig.get,
  post: axiosConfig.post,
  put: axiosConfig.put,
  delete: axiosConfig.delete,
};

const refreshToken = async () => {
  const refreshToken = localStorage.getItem('refresh_token');
  const apiKey = process.env.NEXT_PUBLIC_FIREBASE_API_KEY;

  if (!refreshToken) return;

  try {
    const {
      data: { access_token },
    } = await axios.post(
      process.env.NEXT_PUBLIC_ENV
        ? `https://securetoken.googleapis.com/v1/token?key=${apiKey}`
        : `http://localhost:9099/securetoken.googleapis.com/v1/token?key=${apiKey}`,
      {
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
      },
    );

    setToken(access_token);
  } catch {
    // refresh token failed, redirect => logout
    Router.push('/logout');
  }
};

const refreshTokenAndPerformCall = async <P, F>(
  method: string,
  url: string,
  origin: string,
  body?: P | FormData,
  headers?: F,
) => {
  try {
    await refreshToken();
    setAuthorizationHeader();

    // retry previous call
    const response = await performApiCall({
      method,
      url,
      body,
      headers,
      origin,
    });

    return response;
  } catch (error) {
    Sentry.captureException(error);
    Router.push('/login');

    throw error;
  }
};

export const performApiCall = <P, F>({
  method,
  url,
  body,
  headers,
  origin = process.env.NEXT_PUBLIC_API_URL,
}: {
  method: string;
  url: string;
  body?: P | FormData;
  headers?: F;
  origin?: string;
}) => {
  const action = functionMethodMap[method];
  const urlWithOrigin = `${origin}${url}`;

  if (!body) return action(urlWithOrigin, { headers });

  if (method === 'delete') {
    return action(urlWithOrigin, { data: body }, { headers });
  }

  return action(urlWithOrigin, body, { headers });
};

const api = async <T, P = unknown, F = unknown>({
  method,
  url,
  body,
  headers,
  origin,
  isAuthenticated = true,
}: {
  method: 'post' | 'get' | 'delete' | 'put' | 'patch';
  url: string;
  body?: P | FormData;
  headers?: F;
  origin?: string;
  isAuthenticated?: boolean;
}): Promise<{ data: T; status: number }> => {
  const accessToken = axiosConfig.defaults.headers.common?.[
    'Authorization'
  ] as string;

  if (isAuthenticated) {
    if (!accessToken && isNull(localStorage.getItem('redirect_url'))) {
      localStorage.setItem('redirect_url', Router.asPath);
    }

    const decodedToken = accessToken
      ? getDecodedSessionToken(split(accessToken, ' ')[1])
      : null;

    // refresh token 10s before expiration date (in case of lag etc)
    if (decodedToken && decodedToken.exp * 1000 + 10000 < Date.now()) {
      return refreshTokenAndPerformCall(method, url, origin, body, headers);
    }
  }

  return performApiCall({
    method,
    url,
    body,
    headers,
    origin,
  });
};

export default api;
